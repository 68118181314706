import './stage.scss';

class Stage {
    constructor ($element) {
        this.$stage = $element;
        this.$actionWrapper = this.$stage.querySelector('[data-stage="cta-wrapper"]');
        this.$actionCtas = this.$stage.querySelectorAll('[data-stage="cta"]');
        this.$startContent = this.$stage.querySelector('[data-stage="start"]');
        this.$cursor = this.$stage.querySelector('[data-stage="cursor"]');
        this.isDesktopBp = window.matchMedia('(min-width: 1024px)');
        this.isTouchDevice = window.matchMedia('(pointer: coarse)');
        this.$close = this.$stage.querySelector('[data-stage="close"]');
        this.resized = false;
        this.$header = document.querySelector('.site-header');
        this.$mainHeadline = this.$stage.querySelector('.stage__hero-main-headline');
        this.$mainSubline = this.$stage.querySelector('.stage__hero-subline');
    }

    init () {
        this.$header.classList.add('site-header--is-transparent');
        this.setEvents(this.isTouchDevice.matches);
        this.addHeaderClassOnScroll();
        this.isTouchDevice.addEventListener('change', (e) => {
            this.resized = true;
            this.setEvents(e.matches);
        });
    }

    setEvents (isTouchDevice) {
        const $header = document.querySelector('.site-header');
        const $sectionStage = this.$stage.closest('.stage');

        if (isTouchDevice && !this.resized) {
            // close the overlays
            this.$close.addEventListener('click', () => {
                const $activeCta = this.$actionWrapper.querySelector('.stage__hero-action--active');
                const activeCtaId = $activeCta.dataset.energy;
                const $contentEnergy = this.$stage.querySelector(`[data-stage="${activeCtaId}"]`);
                const $video = $contentEnergy.querySelector('[data-stage="video"]');
                this.$actionWrapper.classList.remove(`stage__hero-actions--${activeCtaId}`);
                $video.classList.remove('stage__hero-video--shown');
                $activeCta.classList.remove('stage__hero-action--active');
                $header.classList.remove('site-header--stage-hero-open');
                $contentEnergy.classList.remove('inview');
                this.hideStartScene(false);
            });
        }

        if (!this.resized) {
            // open the overlay
            if (isTouchDevice) {
                this.$actionCtas.forEach(cta => {
                    cta.addEventListener('click', e => {
                        this.heroAction(e);
                    }, true);
                });
            }
        }

        if (!isTouchDevice) {
            $sectionStage.addEventListener('mousemove', (e) => {
                this.moveCursor(e);
            });

            $sectionStage.addEventListener('mouseleave', (e) => {
                this.$cursor.style.opacity = 0;
            });

            this.$actionCtas.forEach(cta => {
                const circle = cta.querySelector('.stage__hero-action-circle');
                if (this.resized === true) {
                    cta.removeEventListener('click', (e) => {
                        this.heroAction(e);
                    }, true);
                }

                cta.addEventListener('mouseenter', (e) => {
                    const c = circle.getBoundingClientRect();
                    this.$cursor.classList.add('stage__cursor--active');
                    this.$cursor.style.left = `${c.left}px`;
                    this.$cursor.style.top = `${c.top}px`;
                    circle.classList.add('stage__hero-action-circle--active');
                    this.heroAction(e);

                    if (e.target.getAttribute('data-energy') === 'cool-earth-club') {
                        this.$cursor.classList.add('stage__cursor--cool-earth-club');
                        this.$cursor.style.left = `${c.left + 17}px`;
                        this.$cursor.style.top = `${c.top + 26}px`;
                    }
                });

                cta.addEventListener('mouseleave', (e) => {
                    this.$cursor.classList.remove('stage__cursor--active');
                    circle.classList.remove('stage__hero-action-circle--active');
                    this.heroAction(e);

                    if (e.target.getAttribute('data-energy') === 'cool-earth-club') {
                        this.$cursor.classList.remove('stage__cursor--cool-earth-club');
                    }
                });
            });
        }
    }

    heroAction (e) {
        const isActive = e.currentTarget.classList.contains('stage__hero-action--active');
        const $parent = e.currentTarget.parentNode;
        const ctaId = e.currentTarget.dataset.energy;
        const $contentEnergy = e.currentTarget.closest('.stage__hero-content').querySelector(`[data-stage="${ctaId}"]`);
        const $video = $contentEnergy.querySelector('[data-stage="video"]');
        this.hideStartScene(!isActive);

        if (isActive) {
            e.currentTarget.classList.remove('stage__hero-action--active');
            $parent.classList.remove(`stage__hero-actions--${ctaId}`);
            this.$header.classList.remove('site-header--stage-hero-open');
            $contentEnergy.classList.remove('inview');
            $video.classList.remove('stage__hero-video--shown');
        } else {
            e.currentTarget.classList.add('stage__hero-action--active');
            $parent.classList.add(`stage__hero-actions--${ctaId}`);
            this.$header.classList.add('site-header--stage-hero-open');
            $contentEnergy.classList.add('inview');
            $video.classList.add('stage__hero-video--shown');
        }
    }

    hideStartScene (hide) {
        if (hide) {
            this.$startContent.classList.remove('inview');
            this.$close.style.opacity = 1;
        } else {
            this.$startContent.classList.add('inview');
            this.$close.style.opacity = 0;
        }
    }

    moveCursor (e) {
        if (this.$cursor.classList.contains('stage__cursor--active')) {
            return;
        }
        const mouseY = e.clientY;
        const mouseX = e.clientX;
        this.$cursor.style.opacity = 1;
        this.$cursor.style.left = `${mouseX}px`;
        this.$cursor.style.top = `${mouseY}px`;
    }

    addHeaderClassOnScroll () {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0
        };

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    this.$header.classList.add('site-header--is-transparent');
                } else {
                    this.$header.classList.remove('site-header--is-transparent');
                }
            });
        }, options);

        observer.observe(this.$stage);
    }
}

export { Stage };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $stages = $context.querySelectorAll('[data-stage="root"]');

        for (let i = 0; i < $stages.length; i++) {
            const $stage = new Stage($stages[i]);
            $stage.init();
        }
    }
});
